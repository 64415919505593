import React,{useEffect,useState} from 'react'
import './Partner.css'

export default function Partner({apilink,adminId,handlePageClick}) {
    const [allDetails,setAllDetails] = useState([]);
    const [userNoList,setUserNoList] = useState();

    useEffect(() => {
        fetch(`${apilink}/alladmin`)
          .then(response => response.json())
          .then(data => {
            const acIndex = Object.values(data).map(entry => entry.ID).indexOf(Number(adminId));
            setAllDetails([
                Object.values(data).map(entry => entry.name)[acIndex],
                Object.values(data).map(entry => entry.email)[acIndex],
                Object.values(data).map(entry => entry.code)[acIndex]
            ])
          })
          .catch(error => console.error('Error fetching data:', error));

          fetch(`${apilink}/all`)
          .then(response => response.json())
          .then(data => {
            setUserNoList(data)
            // console.log();
          })
          .catch(error => console.error('Error fetching data:', error));
      }, [apilink,adminId]);
  return (
    <div>
      <div className="partner">
        <div className="partner-box">
            <span>Name</span>
            <h2>{allDetails[0]}</h2>
        </div>
        <div className="partner-box">
            <span>Email</span>
            <h2>{allDetails[1]}</h2>
        </div>
        <div className="partner-box">
            <span>Code</span>
            <h2>{allDetails[2]}</h2>
        </div>
        <div className="partner-box">
            <span>Users</span>
            <h2>{userNoList&&userNoList.filter(user => user.refer.includes(allDetails[2])).length}</h2>
        </div>
        <div id={10} onClick={handlePageClick} style={{display:'flex',justifyContent:'center',width:'20%',cursor:'pointer'}} className="partner-box">
            <h2 style={{textAlign:'center'}}>Logout</h2>
        </div>
      </div>
    </div>
  )
}
