import React,{useState} from 'react'
import './Login.css'

export default function Login({apilink,setAdminId}) {
    const [email,setEmail] = useState('')
    const [password,setPassword] = useState('')
    const handleSubmit = ()=>{
        fetch(apilink+'/alladmin')
      .then(response => response.json())
      .then(data => {
        if(email && password){
            if(Object.values(data).map(entry => entry.email).indexOf(email) !== -1){
                const acIndex = Object.values(data).map(entry => entry.email).indexOf(email);
                if(Object.values(data).map(entry => entry.password)[acIndex] === password){
                    console.log('Login Success'+Object.values(data).map(entry => entry.ID)[acIndex])
                    localStorage.setItem('adminId', Object.values(data).map(entry => entry.ID)[acIndex]);
                    setAdminId(String(Object.values(data).map(entry => entry.ID)[acIndex]))
                } else {
                    alert('Wrong Password')
                }
            } else {
                alert('Email doesnt exist')
            }

        } else {
            alert('Enter all details')
        }
              })
      .catch(error => console.error('Error fetching data:', error));

    }
  return (
    <div>
      <div className="login-container">
        <div className="logincard">
            <h2>Admin Login</h2>
            <div className="input-container">
                <span>Email</span>
                <input onChange={e => setEmail(e.target.value)} type="email" />
            </div>
            <div className="input-container">
                <span>Password</span>
                <input onChange={e => setPassword(e.target.value)} type="password" />
            </div>
            <button onClick={handleSubmit} className="login-btn">Login</button>
        </div>
      </div>
    </div>
  )
}
