import './App.css';
import React from 'react'
import Dashboard from './components/Dashboard';
import User from './components/User';
import Home from './components/Home';
import Reports from './components/Reports';
import KYC from './components/KYC';
import Withdraw from './components/Withdraw';
import Login from './components/Login';
import Partner from './components/Partner';
import CreatePartner from './components/CreatePartner';

function App() {
  const [PageView,setPageView] = React.useState(0);
  const [isReports,setIsReports] = React.useState(false);
  const [globaluuid,setglobaluuid] = React.useState('');
  const [adminId,setAdminId] = React.useState(localStorage.getItem('adminId'))
  console.log(localStorage.getItem('adminId'))
  // localStorage.removeItem('adminId');


  const handlePageClick = (e)=>{
    if(e.target.id === '10'){
      localStorage.removeItem('adminId');
      setAdminId();
    } else {
      setPageView(Number(e.target.id))
      console.log(Number(e.target.id))
    }
  }
  const handlePredcitions = (e)=>{
    setIsReports(true)
    setglobaluuid(e.target.id);
  }
  const apilink = 'https://salary.trading/api'
  return (
    <>
    <div className="Universe">
      {!adminId?
      <Login apilink={apilink} setAdminId={setAdminId}/>:
      adminId === '1'?
      PageView === 0?
      <Home apilink={apilink} handlePageClick={handlePageClick}/>:
      PageView === 1?
      <Dashboard apilink={apilink}/>:
      PageView === 2?
      isReports?
      <Reports apilink={apilink} globaluuid={globaluuid}/>:
      <User apilink={apilink} handlePredcitions={handlePredcitions}/>:
      PageView === 3?
      <KYC apilink={apilink}/>:
      PageView === 4?
      <Withdraw apilink={apilink}/>:
      PageView === 5?
      <CreatePartner apilink={apilink}/>:null
      :<Partner apilink={apilink} adminId={adminId} handlePageClick={handlePageClick}/>
    }
    </div>
    </>
  );
}

export default App;
