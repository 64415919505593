import React from 'react'
import './Home.css'

export default function Home({handlePageClick}) {
  return (
    <div style={{paddingTop:'20px',display:'flex',flexWrap:'wrap',height:'100vh',justifyContent:'flex-start',alignContent:'flex-start'}}>
      <div id={1} onClick={handlePageClick} className="homebtn">
        Dashboard
      </div>
      <div id={2} onClick={handlePageClick} className="homebtn">
        Users
      </div>
      <div id={3} onClick={handlePageClick} className="homebtn">
        KYC
      </div>
      <div id={4} onClick={handlePageClick} className="homebtn">
        Withdraw
      </div>
      <div id={5} onClick={handlePageClick} className="homebtn">
        Parnters
      </div>
      <div id={10} onClick={handlePageClick} className="homebtn">
        Logout
      </div>
    </div>
  )
}
