import React, { useState, useEffect } from 'react';
// import axios from 'axios';
import './User.css'

export default function User({apilink,handlePredcitions}) {
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showList, setShowList] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [isEditMode, setIsEditMode] = useState(false);


  useEffect(() => {
    fetch(apilink+'/all')
      .then(response => response.json())
      .then(data => {
        setUsers(data);
        setFilteredUsers(data); // Set filtered users initially
      })
      .catch(error => console.error('Error fetching data:', error));
  }, [apilink]);

  const handleUserClick = (user) => {
    setSelectedUser(user);
    setShowList(false);
  };

  const handleBackClick = () => {
    setSelectedUser(null);
    setShowList(true);
  };

  const handleEditClick = () => {
    setIsEditMode(true);
  };

  const handleSaveClick = () => {
    // const setup = JSON.stringify(selectedUser);
    // axios.post(apilink+'/updateadminuser', {setup})
    //   .then(data => {   

    //     setIsEditMode(false);
    //     // Update the users list with the edited user details
    //     const updatedUsers = users.map(user =>
    //       user.username === data.username ? data : user
    //     );
    //     setUsers(updatedUsers);
    //     setFilteredUsers(updatedUsers);
    //     console.log('User updated:', data);
    //   })
    //   .catch(err => console.log(err));

    fetch(`${apilink}/updateadminuser`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(selectedUser),
    })
      .then(response => response.json())
      .then(data => {
        setIsEditMode(false);
        // Update the users list with the edited user details
        const updatedUsers = users.map(user =>
          user.username === data.username ? data : user
        );
        setUsers(updatedUsers);
        setFilteredUsers(updatedUsers);
        console.log('User updated:', data);
      })
      .catch(error => console.error('Error updating user:', error));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedUser({ ...selectedUser, [name]: value });
  };


  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
    if (query === '') {
      setFilteredUsers(users); // Show all users if search query is empty
    } else {
      const filtered = users.filter(user =>
        user.username.toLowerCase().includes(query) ||
        user.email.toLowerCase().includes(query)
      );
      setFilteredUsers(filtered);
    }
  };
  return (
    <div>
      <div>
      <div>
        {showList ? (
          <div>
            <h1 style={{ marginLeft: '10px' }}>User List</h1>
            <input
              type="text"
              placeholder="Search users"
              value={searchQuery}
              onChange={handleSearch}
            />
            <ul>
              {filteredUsers.map(user => (
                <li style={{ listStyleType: 'none' }} key={user.username} onClick={() => handleUserClick(user)}>
                  <div className="listcard">
                    {user.username} <br /> {user.email}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <div>
            <button className='back-list-btn' onClick={handleBackClick}>Back to List</button>
            <h2>User Details</h2>
            {selectedUser && (
              <div className='list-view'>
                <label>
                  Username:
                  <input
                    type="text"
                    name="username"
                    value={selectedUser.username}
                    onChange={handleInputChange}
                    disabled={!isEditMode}
                  />
                </label>
                <label>
                  Email:
                  <input
                    type="text"
                    name="email"
                    value={selectedUser.email}
                    onChange={handleInputChange}
                    disabled={!isEditMode}
                  />
                </label>
                <label>
                  First Name:
                  <input
                    type="text"
                    name="first"
                    value={selectedUser.first}
                    onChange={handleInputChange}
                    disabled={!isEditMode}
                  />
                </label>
                <label>
                  Last Name:
                  <input
                    type="text"
                    name="last"
                    value={selectedUser.last}
                    onChange={handleInputChange}
                    disabled={!isEditMode}
                  />
                </label>
                <label>
                  Country:
                  <input
                    type="text"
                    name="country"
                    value={selectedUser.country}
                    onChange={handleInputChange}
                    disabled={!isEditMode}
                  />
                </label>
                <label>
                  City:
                  <input
                    type="text"
                    name="city"
                    value={selectedUser.city}
                    onChange={handleInputChange}
                    disabled={!isEditMode}
                  />
                </label>
                <label>
                  Mobile:
                  <input
                    type="text"
                    name="mobile"
                    value={selectedUser.mobile}
                    onChange={handleInputChange}
                    disabled={!isEditMode}
                  />
                </label>
                <label>
                  Status:
                  <input
                    type="text"
                    name="status"
                    value={selectedUser.status}
                    onChange={handleInputChange}
                    disabled={!isEditMode}
                  />
                </label>
                <label>
                  Wallet:
                  <input
                    type="text"
                    name="wallet"
                    value={selectedUser.wallet}
                    onChange={handleInputChange}
                    disabled={!isEditMode}
                  />
                </label>
                <label>
                  IP:
                  <input
                    type="text"
                    name="myip"
                    value={selectedUser.myip}
                    onChange={handleInputChange}
                    disabled={!isEditMode}
                  />
                </label>
                <label>
                  KYC:
                  <input
                    type="text"
                    name="kyc"
                    value={selectedUser.kyc}
                    onChange={handleInputChange}
                    disabled={!isEditMode}
                  />
                </label>
                <label>
                  KYC Data:
                  <input
                    type="text"
                    name="kycdata"
                    value={selectedUser.kycdata}
                    onChange={handleInputChange}
                    disabled={!isEditMode}
                  />
                </label>
                <label>
                  Referral:
                  <input
                    type="text"
                    name="refer"
                    value={selectedUser.refer}
                    onChange={handleInputChange}
                    disabled={!isEditMode}
                  />
                </label>
                <button id={selectedUser.ID} className='userbtn' onClick={handlePredcitions}>Predictions</button>
                {isEditMode ? (
                  <button className='userbtn' onClick={handleSaveClick}>Save</button>
                ) : (
                  <button className='userbtn' onClick={handleEditClick}>Edit</button>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
    </div>
  )
}
