import React,{useEffect,useState} from 'react'
import './CreatePartner.css'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

export default function CreatePartner({apilink}) {
    const [allDetails,setAllDetails] = useState();
    const [userNoList,setUserNoList] = useState();
    const [editIndex, setEditIndex] = useState(null);
    const [updatedPartner, setUpdatedPartner] = useState({});
    const [createPartner, setCreatePartner] = useState({});

    const handleSave =()=>{
       fetch(`${apilink}/updatepartner`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updatedPartner),
    })
      .then(response => response.json())
      .then(data => {
        console.log('Updated successfully:', data);
        setEditIndex(null);
      })
      .catch(error => console.error('Error updating data:', error));
        }
    const handleCreate =()=>{
       fetch(`${apilink}/createpartner`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(createPartner),
    })
      .then(response => response.json())
      .then(data => {
        console.log('Created successfully:', data);
        fetch(`${apilink}/alladmin`)
          .then(response => response.json())
          .then(data => {
            setAllDetails(data);
          })
          .catch(error => console.error('Error fetching data:', error));
      })
      .catch(error => console.error('Error updating data:', error));
        }

        
        const handleChange = (field, value) => {
            setUpdatedPartner(prev => ({ ...prev, [field]: value }));
          };
        const handleChangeCreate = (field, value) => {
            setCreatePartner(prev => ({ ...prev, [field]: value }));
          };

          const handleEdit = (index, user) => {
            setEditIndex(index);
            setUpdatedPartner(user);
          };
    useEffect(() => {
        fetch(`${apilink}/alladmin`)
          .then(response => response.json())
          .then(data => {
            setAllDetails(data);
          })
          .catch(error => console.error('Error fetching data:', error));

          fetch(`${apilink}/all`)
          .then(response => response.json())
          .then(data => {
            setUserNoList(data)
            // console.log('cool'+userNoList&&userNoList.filter(user => user.refer.includes('nishil')).length);
          })
          .catch(error => console.error('Error fetching data:', error));
      }, [apilink]);

      const handleDelete = (id) => {
        fetch(`${apilink}/deletepartner`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ID:id}),
          })
          .then(response => response.json())
          .then(data => {
            console.log('Deleted successfully:', data);
            // Remove the deleted partner from the state
            setAllDetails(allDetails.filter(partner => partner.ID !== id));
          })
          .catch(error => console.error('Error deleting data:', error));
      };
    //   console.log(!!userNoList?userNoList.filter(user => user.refer.includes('nishil')).length:0)
  return (
    <div>
      <div className="create-partner">
        <h1>Add Partner</h1>
        <div style={{marginLeft:'45px'}} className='partner-div'>
                        <Box
                        sx={{
                        '& .MuiTextField-root': { m: 1, width: '25ch' },
                        }}
                        noValidate
                        autoComplete="off"
                        >
                        <TextField
                          id="outlined-required"
                          label="Name"
                          onChange={(e) => handleChangeCreate('name', e.target.value)}
                        />
                        <TextField
                          id="outlined-required"
                          label="Email"
                          onChange={(e) => handleChangeCreate('email', e.target.value)}
                        />
                        <TextField
                          id="outlined-required"
                          label="Password"
                          onChange={(e) => handleChangeCreate('password', e.target.value)}
                        />
                        <TextField
                          id="outlined-required"
                          label="Code"
                          onChange={(e) => handleChangeCreate('code', e.target.value)}
                        />
                        <div>
                        <button onClick={handleCreate} className="edit-partner-btn">Create</button>
                        </div>
                        </Box>
                    </div>
        <h1>Partners</h1>
        <ul>
            {userNoList && allDetails&& allDetails.map((user,index) => (
                <li key={user.ID}>
                    <div className='partner-div'>
                        <Box
                        sx={{
                        '& .MuiTextField-root': { m: 1, width: '25ch' },
                        }}
                        noValidate
                        autoComplete="off"
                        >
                        <TextField
                          disabled={editIndex !== index}
                          id="outlined-required"
                          label="Name"
                          defaultValue={user.name}
                          onChange={(e) => handleChange('name', e.target.value)}
                        />
                        <TextField
                          disabled={editIndex !== index}
                          id="outlined-required"
                          label="Email"
                          defaultValue={user.email}
                          onChange={(e) => handleChange('email', e.target.value)}
                        />
                        <TextField
                          disabled={editIndex !== index}
                          id="outlined-required"
                          label="Password"
                          defaultValue={user.password}
                          onChange={(e) => handleChange('password', e.target.value)}
                        />
                        <TextField
                          disabled={editIndex !== index}
                          id="outlined-required"
                          label="Code"
                          defaultValue={user.code}
                          onChange={(e) => handleChange('code', e.target.value)}
                        />
                        <TextField
                          id="outlined-required"
                          label="Total Users"
                          
                          defaultValue={userNoList.filter(userr => userr.refer.includes(user.code)).length}
                          InputProps={{readOnly:true}}
                        />
                        <div>
                        {editIndex === index?
                        <button onClick={handleSave} className="edit-partner-btn">Save</button>:
                        <button onClick={() => handleEdit(index, user)} className="edit-partner-btn">Edit</button>
                        }
                        <button onClick={() => handleDelete(user.ID)} className="delete-partner-btn">Delete</button>
                        </div>
                        </Box>
                    </div>
                </li>
            ))}
        </ul>
      </div>
    </div>
  )
}
